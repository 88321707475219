import { render, staticRenderFns } from "./HeaderElement.vue?vue&type=template&id=3d8731ac"
import script from "./HeaderElement.vue?vue&type=script&lang=js"
export * from "./HeaderElement.vue?vue&type=script&lang=js"
import style0 from "./HeaderElement.vue?vue&type=style&index=0&id=3d8731ac&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerNavigationBar: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerNavigationBar.js').default,UiButtonComponent: require('/home/node/app/components/ui/ButtonComponent.vue').default,EditorLink: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js').default,ControllerSearch: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerSearch.js').default,ControllerHeader: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerHeader.js').default})
