
import VueHorizontal from "vue-horizontal";
export default {
  components: { VueHorizontal },
  data() {
    return {
      left: 0,
      originX: 0,
      originLeft: 0,
      relatedPosts: null,
      translatedCategories: [],
    };
  },
  mounted() {
    if (this.$refs.singlePost && this.$refs.singlePost.pageData) {
      if (this.$refs.singlePost.pageData.relatedPosts) {
        this.relatedPosts = this.$refs.singlePost.pageData.relatedPosts;
      }

      setTimeout(() => {
        if (
          this.$refs.singlePost.pageData.terms &&
          this.$refs.singlePost.pageData.terms.categories
        ) {
          const postCategories =
            this.$refs.singlePost.pageData.terms.categories;
          this.getTranslatedCategories(postCategories);
        }
      }, 300);
    }

    const links = document.getElementsByClassName("relatedPostLink");
    if (links && links.length) {
      Array.from(links).forEach((link) => {
        link.removeAttribute("target");
      });
    }
  },
  methods: {
    getTranslatedCategories(categories) {
      if (categories && categories.length) {
        categories.forEach((category) => {
          if (
            category &&
            category.translations &&
            category.translations.length
          ) {
            category.translations.forEach((translate) => {
              if (
                (this.$i18n.locale === "hr" &&
                  `${this.$i18n.locale}v` === translate.language_code) ||
                (this.$i18n.locale === "en" &&
                  `${this.$i18n.locale}g` === translate.language_code)
              ) {
                this.translatedCategories.push(translate.title);
              }
            });
          }
        });
      }
    },
    async routerPush(tagTitle) {
      await this.$router.push({
        path: "filter",
        query: {
          tag: tagTitle,
        },
      });
    },
    returnMediaObject(index) {
      var mediaObject;
      if (
        this.$refs.singlePost &&
        this.$refs.singlePost.pageData &&
        this.$refs.singlePost.pageData.relatedEntities
      ) {
        mediaObject =
          this.$refs.singlePost.pageData.relatedEntities[index]?.connectedPost
            .media;
      }
      return mediaObject;
    },

    // horizontal
    onScroll({ left }) {
      this.left = left;
    },
    onMouseDown(e) {
      this.originX = e.pageX;
      this.originLeft = this.left;

      window.addEventListener("mouseup", this.onMouseUp, { passive: true });
      window.addEventListener("mousemove", this.onMouseMove, { passive: true });
    },
    onMouseUp() {
      window.removeEventListener("mouseup", this.onMouseUp, { passive: true });
      window.removeEventListener("mousemove", this.onMouseMove, {
        passive: true,
      });
    },
    onMouseMove(e) {
      const offset = e.pageX - this.originX;
      const left = this.originLeft - offset;

      this.$refs.horizontal.scrollToLeft(left, "auto");
    },
  },
};
