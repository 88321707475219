
export default {
  props: {
    text: {
      type: String,
      default: "Button",
    },
    iconClass: {
      type: String,
      default: "icon-arrow",
    },
    type: {
      type: String,
      default: "text",
    },
    link: {
      type: [String, Object],
    },
    icon: {
      type: Boolean,
      default: false,
    },
    underline: {
      type: Boolean,
      default: false,
    },
    underlineColor: {
      type: String,
      default: "transparent",
    },
    buttonWithBg: {
      type: Boolean,
      default: false,
    },
    linkClass: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    linkAriaLabel: {
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.underline) {
      document.documentElement.style.setProperty(
        "--underline-color",
        this.underlineColor
      );
    }
  },
};
