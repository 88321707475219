
import * as vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      searchOpen: false,
      menuOpen: false,
    };
  },
  watch: {
    $route() {
      this.closeSearch();
      this.closeMenu();
    },
  },

  methods: {
    openSearch() {
      this.searchOpen = true;

      if (document.getElementById("searchInput")) {
        setTimeout(() => {
          document.getElementById("searchInput").focus();
        }, 300);
      }
    },
    toggleMenu(e) {
      const el = e.currentTarget;
      if (el) {
        this.menuOpen = el.classList.contains("menuOpen");
      }

      const element = document.documentElement;
      if (element) {
        if (this.menuOpen) {
          element.classList.add("overflow-hidden");
        } else {
          element.classList.remove("overflow-hidden");
        }
      }
    },
    closeMenu() {
      this.menuOpen = false;
      const openClass = document.getElementsByClassName("menuOpen");
      if (openClass && openClass[0]) {
        openClass[0].classList.remove("menuOpen");
      }

      const element = document.documentElement;
      if (element) {
        element.classList.remove("overflow-hidden");
      }
    },
    closeSearch() {
      this.searchOpen = false;
    },
  },
};
