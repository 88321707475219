
export default {
  props: {
    endpoint: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    quote: {
      type: String,
      default: "",
    },
    hashtags: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      baseUrl: null,
      networks: [
        {
          slug: "facebook",
          name: "Facebook",
          fontSize: "font-size: 24px",
        },
        {
          slug: "twitter",
          name: "Twitter",
          fontSize: "font-size: 24px",
        },
        {
          slug: "linkedIn",
          name: "linkedin",
          fontSize: "font-size: 24px",
        },
      ],
    };
  },
  mounted() {
    this.baseUrl = window.location.origin;
  },
};
