// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Axiforma/Axiforma-Thin.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Axiforma/Axiforma-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Axiforma/Axiforma-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Axiforma/Axiforma-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Axiforma/Axiforma-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./Axiforma/Axiforma-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./Axiforma/Axiforma-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./Axiforma/Axiforma-Black.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./Cabin/Cabin-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./Cabin/Cabin-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./Cabin/Cabin-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_11___ = require("./Cabin/Cabin-Bold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Axiforma\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:100;font-style:normal;font-display:swap}@font-face{font-family:\"Axiforma\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:300;font-style:normal;font-display:swap}@font-face{font-family:\"Axiforma\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400;font-style:normal;font-display:swap}@font-face{font-family:\"Axiforma\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:500;font-style:normal;font-display:swap}@font-face{font-family:\"Axiforma\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");font-weight:600;font-style:normal;font-display:swap}@font-face{font-family:\"Axiforma\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:700;font-style:normal;font-display:swap}@font-face{font-family:\"Axiforma\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"truetype\");font-weight:800;font-style:normal;font-display:swap}@font-face{font-family:\"Axiforma\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"truetype\");font-weight:900;font-style:normal;font-display:swap}@font-face{font-family:\"Cabin\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"truetype\");font-weight:400;font-style:normal;font-display:swap}@font-face{font-family:\"Cabin\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"truetype\");font-weight:500;font-style:normal;font-display:swap}@font-face{font-family:\"Cabin\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"truetype\");font-weight:600;font-style:normal;font-display:swap}@font-face{font-family:\"Cabin\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"truetype\");font-weight:700;font-style:normal;font-display:swap}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
