
import VueHorizontal from "vue-horizontal";
export default {
  components: { VueHorizontal },
  data() {
    return {
      left: 0,
      originX: 0,
      originLeft: 0,
      categories: [],
      categoriesLength: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      // categories length
      if (
        this.$refs.categories &&
        this.$refs.categories._data &&
        this.$refs.categories._data.postCategories &&
        this.$refs.categories._data.postCategories.length
      ) {
        this.categoriesLength =
          this.$refs.categories._data.postCategories.length;
      }
    }, 700);
  },
  methods: {
    appendCategory(category) {
      const blogRef = this.$refs["blog-list"];
      if (blogRef && blogRef.options && blogRef.options.page) {
        blogRef.options.page = 1;
      }
      if (category === null) {
        this.categories.splice(0, this.categories.length);
        return false;
      }
      this.categories.splice(this.categories.indexOf(category.slug), 1);
      this.categories.push(category.slug);
    },

    // horizontal
    onScroll({ left }) {
      this.left = left;
    },
    onMouseDown(e) {
      this.originX = e.pageX;
      this.originLeft = this.left;

      window.addEventListener("mouseup", this.onMouseUp, { passive: true });
      window.addEventListener("mousemove", this.onMouseMove, { passive: true });
    },
    onMouseUp() {
      window.removeEventListener("mouseup", this.onMouseUp, { passive: true });
      window.removeEventListener("mousemove", this.onMouseMove, {
        passive: true,
      });
    },
    onMouseMove(e) {
      const offset = e.pageX - this.originX;
      const left = this.originLeft - offset;

      this.$refs.horizontal.scrollToLeft(left, "auto");
    },
  },
};
